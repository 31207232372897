<template>
    <div class="alert-frame my-3 py-3 py-sm-0">
        <div class="col-2 icon d-sm-inline-block d-none">!</div>
        <div class="col-8">
            К управления квадроциклами допускаются взрослые с 21 года, дети в качестве пассажира в сопровождении взрослого с 10 лет.
        </div>
        <div class="col-2 icon d-sm-inline-block d-none">!</div>
    </div>
</template>

<script>
    export default {
        name: 'AlertFrame',
        props: {}
    }
</script>

<style scoped>
    .alert-frame {
        background-color: #ff000080;
        margin: 0 -10px;
        padding: 0px 10px;
        text-align: center;
        font-size: 1.2em;
        font-weight: bold;
    }
    .alert-frame > div {
        display: inline-block;
    }
    .icon {
        font-size: 82px;
        padding: 0 15px;
        color: #ff0000;
        text-shadow: 1px 1px 1px #000000;
    }
</style>
