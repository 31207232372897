<template>
    <div class="clock-element">
        <div class="img mt-3"></div>
        <div class="text">{{time}}</div>
    </div>
</template>

<script>
    export default {
        name: 'ClockFrame',
        props: {
            time: String,
        },
        data() {
            return {}
        },
    }
</script>

<style scoped>
    .clock-element {
        position: relative;
        width: 100px;
    }
    .img {
        display: block;
        width: 100px;
        height: 100px;
        margin: 0 auto;
        border-radius: 75px;
        background-position: center;
        background-size: cover;
        background-image: url("~@/assets/images/clock.png");
        opacity: 0.5;
    }
    .text {
        color: #008800;
        font-family: 'Pattaya';
        font-size: 36px;
        text-align: center;
        width: 100px;
        display: block;
        position: absolute;
        top: 25px;
        left: 0;
        text-shadow: 1px 1px 1px rgb(0 0 0);
    }
</style>
