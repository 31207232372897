<template>
    <div class="first-frame">
        <div class="container container-xxl p-0 vh-100">
            <div class="row h-75 align-items-center m-0">
                <div class="logo">
                    <img src="@/assets/images/quadro-logo.png" />
                    <p class="title">Villa Monte Gagra</p>
                    <p class="sub-title">
                        Квадроциклы в Aбхазии
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FirstFrame',
        props: {}
    }
</script>

<style scoped>

    p {
        margin: 0;
        padding: 0;
    }

    .first-frame {}
    .logo {
        width: 300px;
        height: 370px;
        box-shadow: 0 0 10px rgb(0 0 0 / 50%);
        padding: 20px;
        border-radius: 30px;
        background-color: rgba(255, 255, 255, 0.70);
        text-align: center;
        margin: 0 auto;
    }
    .logo > img {
        width: 100%;
    }
    .logo .title {
        font-family: Pattaya;
        font-size: 32px;
        margin-top: 16px;
        color: #ed1720;
        text-shadow: 2px 2px 2px rgb(0 0 0);
    }
    .logo .sub-title {
        font-family: Alegreya;
        font-size: 24px;
        color: #008800;
        margin-top: 16px;
        text-shadow: 1px 1px 1px rgb(0 0 0);
        line-height: 24px;
    }
</style>
