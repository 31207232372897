<template>
    <div class="about-frame">
        <div class="row m-0 mt-5">
            <div class="title">Вилла Монте Гагра</div>
            <div>
                <img src="@/assets/images/hotel.jpg" class="float-left in-text">
                <p>
                    Отель «Вилла Монте Гагра» расположен в Гагре, в 2,5 км от железнодорожного вокзала Гагры и пляжа
                    Черного моря. К услугам гостей открытый бассейн с подогревом. Гости могут бесплатно пользоваться
                    зонтиками и шезлонгами.
                </p>
                <p>
                    Все номера оснащены кондиционером и телевизором со спутниковыми каналами. Из окон открывается вид на
                    горы и реку.
                </p>
                <p class="text-center">
                    <a href="/" class="btn btn-warning mt-3">Подробнее</a>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'HotelFrame',
        props: {}
    }
</script>

<style scoped>
    p {
        padding: 0;
        margin: 0;
    }
    .in-text {
        width: 100%;
        height: 200px;
        max-width: 350px;
        float: left;
        margin-right: 10px;
        margin-bottom: 10px;
        box-shadow: 0 0 10px rgb(0 0 0 / 50%);
        border-radius: 10px;
        object-fit: cover;
    }
    .title2 {
        display: block;
        width: 100%;
        text-align: center;
        font-family: Pattaya;
        font-size: 24px;
        color: #ed1720;
    }
</style>
