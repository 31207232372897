<template>
    <div class="gallery-frame">
        <div class="row m-0">
            <div class="col-6 col-sm-3 p-0">
                <img v-img:gallery src="@/assets/images/gallery/01.jpg">
            </div>
            <div class="col-6 col-sm-3 p-0">
                <img v-img:gallery src="@/assets/images/gallery/02.jpg">
                <img v-img:gallery src="@/assets/images/gallery/03.jpg" class="d-none">
                <img v-img:gallery src="@/assets/images/gallery/04.jpg" class="d-none">
                <img v-img:gallery src="@/assets/images/gallery/05.jpg" class="d-none">
            </div>
            <div class="col-6 col-sm-3 p-0">
                <img v-img:gallery src="@/assets/images/gallery/06.jpg">
                <img v-img:gallery src="@/assets/images/gallery/07.jpg" class="d-none">
                <img v-img:gallery src="@/assets/images/gallery/08.jpg" class="d-none">
            </div>
            <div class="col-6 col-sm-3 p-0">
                <img v-img:gallery src="@/assets/images/gallery/09.jpg">
            </div>
            <div class="col-6 col-sm-3 p-0">
                <img v-img:gallery src="@/assets/images/gallery/10.jpg">
                <img v-img:gallery src="@/assets/images/gallery/11.jpg" class="d-none">
            </div>
            <div class="col-6 col-sm-3 p-0">
                <img v-img:gallery src="@/assets/images/gallery/12.jpg">
                <img v-img:gallery src="@/assets/images/gallery/13.jpg" class="d-none">
                <img v-img:gallery src="@/assets/images/gallery/14.jpg" class="d-none">
                <img v-img:gallery src="@/assets/images/gallery/15.jpg" class="d-none">
                <img v-img:gallery src="@/assets/images/gallery/16.jpg" class="d-none">
                <img v-img:gallery src="@/assets/images/gallery/17.jpg" class="d-none">
                <img v-img:gallery src="@/assets/images/gallery/18.jpg" class="d-none">
            </div>
            <div class="col-6 col-sm-3 p-0">
                <img v-img:gallery src="@/assets/images/gallery/19.jpg">
                <img v-img:gallery src="@/assets/images/gallery/20.jpg" class="d-none">
                <img v-img:gallery src="@/assets/images/gallery/21.jpg" class="d-none">
            </div>
            <div class="col-6 col-sm-3 p-0 last-image">
                <div class="b-image"></div>
                <div class="centered" style="filter: brightness(100);">+12 фото</div>
                <img v-img:gallery src="@/assets/images/gallery/01.jpg">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Gallery',
    }
</script>

<style scoped>
    img {
        width: 100%;
    }
    .last-image {
        position: relative;
        text-align: center;
        color: #ffffff;
        font-size: 26px;
    }
    .last-image > .b-image {
        background-image: url("~@/assets/images/gallery/05.jpg");
        filter: brightness(0.25);
        width: 100%;
        height: 100%;
    }

    .last-image > img {
        filter: opacity(0);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .centered {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
</style>
