<template>
    <div class="gallery-frame mt-3">
        <div class="row m-0">
            <div class="col-6 col-sm-3 p-0">
                <img v-img:gallery src="@/assets/images/gallery-quadro/01.jpg">
            </div>
            <div class="col-6 col-sm-3 p-0">
                <img v-img:gallery src="@/assets/images/gallery-quadro/02.jpg">
                <img v-img:gallery src="@/assets/images/gallery-quadro/03.jpg" class="d-none">
                <img v-img:gallery src="@/assets/images/gallery-quadro/04.jpg" class="d-none">
                <img v-img:gallery src="@/assets/images/gallery-quadro/05.jpg" class="d-none">
            </div>
            <div class="col-6 col-sm-3 p-0">
                <img v-img:gallery src="@/assets/images/gallery-quadro/06.jpg">
                <img v-img:gallery src="@/assets/images/gallery-quadro/07.jpg" class="d-none">
                <img v-img:gallery src="@/assets/images/gallery-quadro/08.jpg" class="d-none">
            </div>
            <div class="col-6 col-sm-3 p-0">
                <img v-img:gallery src="@/assets/images/gallery-quadro/09.jpg">
            </div>
            <div class="col-6 col-sm-3 p-0">
                <img v-img:gallery src="@/assets/images/gallery-quadro/10.jpg">
                <img v-img:gallery src="@/assets/images/gallery-quadro/11.jpg" class="d-none">
            </div>
            <div class="col-6 col-sm-3 p-0">
                <img v-img:gallery src="@/assets/images/gallery-quadro/12.jpg">
                <img v-img:gallery src="@/assets/images/gallery-quadro/13.jpg" class="d-none">
                <img v-img:gallery src="@/assets/images/gallery-quadro/14.jpg" class="d-none">
                <img v-img:gallery src="@/assets/images/gallery-quadro/15.jpg" class="d-none">
                <img v-img:gallery src="@/assets/images/gallery-quadro/16.jpg" class="d-none">
                <img v-img:gallery src="@/assets/images/gallery-quadro/17.jpg" class="d-none">
                <img v-img:gallery src="@/assets/images/gallery-quadro/18.jpg" class="d-none">
            </div>
            <div class="col-6 col-sm-3 p-0">
                <img v-img:gallery src="@/assets/images/gallery-quadro/19.jpg">
                <img v-img:gallery src="@/assets/images/gallery-quadro/20.jpg" class="d-none">
                <img v-img:gallery src="@/assets/images/gallery-quadro/21.jpg" class="d-none">
                <img v-img:gallery src="@/assets/images/gallery-quadro/22.jpg" class="d-none">
                <img v-img:gallery src="@/assets/images/gallery-quadro/23.jpg" class="d-none">
                <img v-img:gallery src="@/assets/images/gallery-quadro/24.jpg" class="d-none">
                <img v-img:gallery src="@/assets/images/gallery-quadro/25.jpg" class="d-none">
                <img v-img:gallery src="@/assets/images/gallery-quadro/26.jpg" class="d-none">
            </div>
            <div class="col-6 col-sm-3 p-0 last-image">
                <div class="b-image"></div>
                <div class="centered" style="filter: brightness(100);">+27 фото</div>
                <img v-img:gallery src="@/assets/images/gallery-quadro/01.jpg">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Gallery',
    }
</script>

<style scoped>
    img {
        width: 100%;
    }
    .last-image {
        position: relative;
        text-align: center;
        color: #ffffff;
        font-size: 26px;
    }
    .last-image > .b-image {
        background-image: url("~@/assets/images/gallery/05.jpg");
        filter: brightness(0.25);
        width: 100%;
        height: 100%;
    }

    .last-image > img {
        filter: opacity(0);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .centered {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
</style>
