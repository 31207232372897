<template>
    <div id="quadro">
        <FirstFrame/>
        <div class="container frame">
            <AboutFrame/>
            <BeginTime/>
            <DetailsFrame/>
            <AlertFrame/>
            <PriceFrame/>
            <EquipsFrame/>
            <GalleryFrame/>
            <ContactFrame/>
            <HotelFrame/>
        </div>
        <FooterFrame/>
    </div>
</template>

<script>
    import FirstFrame from './components/quadro/FirstFrame.vue'
    import AboutFrame from './components/quadro/AboutFrame.vue'
    import BeginTime from './components/quadro/BeginTimeFrame.vue'
    import DetailsFrame from './components/quadro/DetailsFrame.vue'
    import AlertFrame from './components/quadro/AlertFrame.vue'
    import PriceFrame from './components/quadro/PriceFrame.vue'
    import EquipsFrame from './components/quadro/EquipsFrame.vue'
    import GalleryFrame from './components/quadro/GalleryFrame.vue'
    import ContactFrame from './components/hotel/ContactFrame.vue'
    import HotelFrame from './components/quadro/HotelFrame.vue'
    import FooterFrame from './components/hotel/FooterFrame.vue'

    export default {
        name: 'Hotel',
        components: {
            FirstFrame,
            AboutFrame,
            BeginTime,
            DetailsFrame,
            AlertFrame,
            PriceFrame,
            EquipsFrame,
            GalleryFrame,
            ContactFrame,
            FooterFrame,
            HotelFrame
        }
    }
</script>

<style>
    #quadro {
        font-family: Alegreya, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #000000;
        font-size: 16px;
        background-image: url("~@/assets/images/quadro-bg.jpg");
        background-repeat: no-repeat;
        background-position-y: bottom;
        background-position-x: center;
        background-size: cover;
        background-attachment: fixed;
    }

    .frame {
        box-shadow: 0 0 10px rgb(0 0 0 / 50%);
        padding: 20px 10px;
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 0.85);
        text-align: justify;
    }

    .title {
        text-align: center;
        font-family: Pattaya;
        font-size: 30px;
        color: #008800;
        text-shadow: 1px 1px 1px rgb(0 0 0);
    }

    @font-face {
        font-family: "Pattaya";
        src: local("Pattaya"),
        url(~@/assets/fonts/Pattaya/Pattaya-Regular.ttf) format("truetype");
    }

    @font-face {
        font-family: "Alegreya";
        src: local("Alegreya"),
        url(~@/assets/fonts/Alegreya/Alegreya-VariableFont_wght.ttf) format("truetype");
    }
</style>
