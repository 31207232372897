<template>
    <div class="reviews-frame">
        <div class="row m-0 mt-3">
            <div class="title">Требования к одежде</div>
            <div class="row">
                <div class="col-lg-4 text-center" v-for="equip in equips" :key="equip.id">
                    <EquipElement
                        v-bind:image="equip.image"
                        v-bind:name="equip.name"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import EquipElement from './EquipElement.vue'

    export default {
        name: 'EquipsFrame',
        components: {
            EquipElement,
        },
        props: {},
        data() {
            return {
                equips: [
                    {
                        image: require('../../assets/images/equip-top.jpg'),
                        name: "Рубашка или кофта с длинными рукавами",
                    },
                    {
                        image: require('../../assets/images/equip-middle.jpg'),
                        name: "Плотные брюки или джинсы",
                    },
                    {
                        image: require('../../assets/images/equip-bottom.jpg'),
                        name: "Кроссовки или другая защищенная обувь",
                    }
                ]
            }
        },
    }
</script>

<style scoped>

</style>
