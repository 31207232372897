<template>
    <div class="about-frame">
        <div class="row m-0">
            <div class="title">Абхазия с другой стороны!</div>
            <div>
                <img src="@/assets/images/quadro-about.jpg" class="float-left in-text">
                <p>
                    Наша компания предлагает вам окунуться в живописнейшие места. В непроходимых джунглях Абхазии вы сможете увидеть эвкалиптовые рощи, реликтовые гималайские сосны, настоящие плантации мандаринов и апельсинов, откроете для себя жизнь абхазской глубинки, увидите трудолюбивых пастухов с их стадом козочек.
                </p>
                <p>
                    Горная речка, вид величественных гор Кавказа и сияющее голубым цветом Черное море.
                </p>
                <p>
                    Вы будете вспоминать это экстремальное, захватывающее путешествие долгими зимними вечерами.
                </p>
                <p>
                    Опытные инструкторы проведут инструктаж, объяснят основы управления, выдадут защитное снаряжение и будут сопровождать Вас на протяжении всего маршрута и всегда окажут помощь.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AboutFrame',
        props: {}
    }
</script>

<style scoped>
    .in-text {
        width: 100%;
        max-width: 350px;
        float: left;
        margin-right: 10px;
        margin-bottom: 10px;
        box-shadow: 0 0 10px rgb(0 0 0 / 50%);
        border-radius: 10px;
    }
</style>
