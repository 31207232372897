<template>
    <div class="details-frame mt-3">
        <div class="row" style="justify-content: center;">
            <div class="col-lg-6 detail">
                <div class="row" style="justify-content: center;">
                    <div class="col-lg img">
                        <img src="@/assets/images/icons-map-pointer.png"/>
                    </div>
                    <div class="col-lg text" style="justify-content: center;">
                        Начало маршрута находится в Псахаре (Колхиде) в окрестностях г. Гагра.
                    </div>
                </div>
            </div>
            <div class="col-lg-6 detail">
                <div class="row" style="justify-content: center;">
                    <div class="col-lg img">
                        <img src="@/assets/images/icons-travel.png"/>
                    </div>
                    <div class="col-lg text" style="justify-content: center;">
                        Маршрут рассчитан на 1,5 часа.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'DetailsFrame',
        props: {}
    }
</script>

<style scoped>
    .detail {
        background-color: transparent;
        border: 0;
        text-align: center;
        max-width: 500px;
        margin: 15px 0;
    }

    .detail .img {
        width: 100px;
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .detail .img img {
        width: 100px;
        filter: opacity(.7) drop-shadow(0 0 0 #FF0000);
    }

    .text {
        display: flex;
        align-items: center;
        font-size: 20px;
    }
</style>
