<template>
    <div class="about-frame">
        <div class="row m-0">
            <div class="title">Вилла Монте Гагра</div>
            <p>
                <img src="@/assets/images/hotel.jpg" class="float-left in-text">
                Гагры - это удивительное место! Подарите себе и своим близким незабываемый отдых
                в Гаграх вдали от городской суеты.
                <br/>
                Если вы хотите не только хорошо провести время в Абхазии, в городе-курорте Гагра,
                но и при этом иметь шикарный, комфортабельный номер с видом на прекрасный пейзаж,
                то вам, однозначно, к нам, ведь хороший отдых должен проходить с комфортом. Наша
                Вилла находится в экологически чистом районе, в предгорьях Кавказских гор, рядом с
                горной речкой, недалеко от моря. Так же недалеко от нашей Виллы располагаются
                прекрасные рестораны абхазской кухни, в которых вы сможете замечательно покушать,
                приятно провести время и поверьте, цены, и качество вас приятно удивят.
                <br/>
                <span class="d-flex  mt-3" style="justify-content: center;">
                    <a v-show="!text_more" v-on:click="text_more = true" class="btn btn-warning">Читать далее</a>
                </span>
                <span v-show="text_more">
                Наша двухэтажная вилла в Гагре разделена на две половины, где к каждой половине
                свой отдельный вход. Это невероятно уютная и комфортабельная вилла, вы в этом можете
                убедиться сами, так как на первом этаже апартаменты, оборудованные кондиционером на
                четыре спальных места, с дополнительным пятым местом, а так же комфортная душевая
                кабина и санузел. Тем, кто любит самостоятельно готовить домашнюю пищу, оборудована
                отличная кухня со всеми необходимыми приборами и удобствами. Телевизор,
                холодильник, кондиционер, стиральная машина - это самая малое нашей уютной виллы,
                многих подкупает именно открытая терраса с видом на горную реку. На втором этаже
                располагаются еще одни апартаменты, состоящие из двух комнат, санузла с душевой
                кабиной и стиральной машинкой, также есть холодильник и микроволновая печь. Еще на
                втором этаже имеется трехместный и двухместный номера, которые также
                комфортабельны, оснащены кондиционерами и телевизорами. На улице расположена
                большая беседка общего пользования с полностью оборудованной кухней, посудой и
                холодильником.
                <br/>
                В распоряжении гостей отеля «Вилла Монте Гагра» принадлежности для барбекю
                бесплатно. Wi-Fi на территории Виллы также предоставляется бесплатно. На территории
                и в окрестностях можно заняться различными видами активного отдыха, в том числе
                ходьбой по пересеченной местности в Абхазский джунглях. В отеле «Вилла
                Монте Гагра» мы организовываем экскурсии на квадроциклах. На территории обустроена
                бесплатная парковка.
                <br/>
                К вашим услугам с апреля открывается большой бассейн с зоной отдыха и солярием.
                Так же мы готовы предоставить детскую кроватку и детский стульчик, что бы Вы и Ваша
                семья чувствовали себя как дома!
                <br/>
                Приезжайте к нам, и вы никогда не забудете свой отпуск в Гаграх!
                </span>
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AboutFrame',
        props: {},
        data: function () {
            return {
                text_more: false,
            }
        },
    }
</script>

<style scoped>
    .in-text {
        width: 100%;
        max-width: 350px;
        float: left;
        margin-right: 10px;
        margin-bottom: 10px;
        box-shadow: 0 0 10px rgb(0 0 0 / 50%);
        border-radius: 10px;
    }
</style>
