<template>
    <div class="about-frame">
        <div class="row m-0 mt-5">
            <div class="title">Квадроциклы в Aбхазии</div>
            <div>
                <img src="@/assets/images/quadro.jpg" class="float-left in-text">
                <span class="title2">Мы покажем Вам Абхазию с другой, неизведанной стороны!</span>
                Наша компания предлагает вам окунуться в живописнейшие места. В непроходимых джунглях Абхазии вы сможете
                увидеть эвкалиптовые рощи, реликтовые гималайские сосны, настоящие плантации мандаринов и апельсинов,
                откроете для себя жизнь абхазской глубинки, увидите трудолюбивых пастухов с их стадом козочек.
                <p class="text-center">
                    <a href="/quadro" class="btn btn-warning mt-3">Подробнее</a>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AboutFrame',
        props: {}
    }
</script>

<style scoped>
    .in-text {
        width: 100%;
        max-width: 350px;
        float: left;
        margin-right: 10px;
        margin-bottom: 10px;
        box-shadow: 0 0 10px rgb(0 0 0 / 50%);
        border-radius: 10px;
    }
    .title2 {
        display: block;
        width: 100%;
        text-align: center;
        font-family: Pattaya;
        font-size: 24px;
        color: #ed1720;
    }
</style>
