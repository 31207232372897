<template>
    <div class="equip-element">
        <div class="image mt-3" v-bind:style="{ backgroundImage: 'url(' + image + ')' }"></div>
        <div class="name">{{name}}</div>
    </div>
</template>

<script>
    export default {
        name: 'EquipFrame',
        props: {
            image: String,
            name: String,
            text: String,
        },
        data() {
            return {}
        },
    }
</script>

<style scoped>
    .image {
        display: block;
        width: 150px;
        height: 150px;
        margin: 0 auto;
        border-radius: 75px;
        background-position: center;
        background-size: cover;
    }
    .name {
        color: #ed1720;
        font-weight: bold;
        font-size: 20px;
    }
</style>
