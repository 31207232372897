<template>
    <div id="hotel">
        <FirstFrame/>
        <div class="container frame">
            <AboutFrame/>
            <PriceFrame/>
            <GalleryFrame/>
            <ContactFrame/>
            <ReviewsFrame/>
            <QuadroFrame/>
        </div>
        <FooterFrame/>
    </div>
</template>

<script>
    import FirstFrame from './components/hotel/FirstFrame.vue'
    import AboutFrame from './components/hotel/AboutFrame.vue'
    import PriceFrame from './components/hotel/PriceFrame.vue'
    import GalleryFrame from './components/hotel/GalleryFrame.vue'
    import ContactFrame from './components/hotel/ContactFrame.vue'
    import ReviewsFrame from './components/hotel/ReviewsFrame.vue'
    import FooterFrame from './components/hotel/FooterFrame.vue'
    import QuadroFrame from "@/components/hotel/QuadroFrame";

    export default {
        name: 'Hotel',
        components: {
            FirstFrame,
            AboutFrame,
            PriceFrame,
            GalleryFrame,
            ContactFrame,
            ReviewsFrame,
            FooterFrame,
            QuadroFrame
        }
    }
</script>

<style>
    #hotel {
        font-family: Alegreya, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #000000;
        font-size: 16px;
        background-image: url("~@/assets/images/first-frame.jpg");
        background-repeat: no-repeat;
        background-position-y: bottom;
        background-position-x: center;
        background-size: cover;
        background-attachment: fixed;
    }

    .frame {
        box-shadow: 0 0 10px rgb(0 0 0 / 50%);
        padding: 20px 10px;
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 0.85);
        text-align: justify;
    }

    .title {
        text-align: center;
        font-family: Pattaya;
        font-size: 30px;
        color: #008800;
        text-shadow: 1px 1px 1px rgb(0 0 0);
    }

    @font-face {
        font-family: "Pattaya";
        src: local("Pattaya"),
        url(~@/assets/fonts/Pattaya/Pattaya-Regular.ttf) format("truetype");
    }

    @font-face {
        font-family: "Alegreya";
        src: local("Alegreya"),
        url(~@/assets/fonts/Alegreya/Alegreya-VariableFont_wght.ttf) format("truetype");
    }
</style>
