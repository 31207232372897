import Vue from 'vue'
import Hotel from './Hotel.vue'
import Quadro from './Quadro.vue'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import VueImg from 'v-img'
import {library} from '@fortawesome/fontawesome-svg-core'
import {faPhone, faHome, faEnvelope} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
library.add(faPhone, faHome, faEnvelope)
Vue.component('font-awesome-icon', FontAwesomeIcon)

const vueImgConfig = {
    altAsTitle: false,
    sourceButton: false,
    openOn: 'click',
    thumbnails: false,
}
Vue.use(VueImg, vueImgConfig);

Vue.config.productionTip = false

const routes = {
    '/': Hotel,
    '/quadro': Quadro
}

new Vue({
    el: '#app',
    data: {
        currentRoute: window.location.pathname
    },
    computed: {
        ViewComponent() {
            return routes[this.currentRoute] || Hotel
        }
    },
    render(h) {
        return h(this.ViewComponent)
    }
})
