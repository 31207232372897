<template>
    <div class="begin-time">
        <div class="row m-0">
            <div class="large-text">Выезды осуществляются каждый день, каждые два часа, в зависимости от наполнения групп.</div>
            <div class="row text-center" style="justify-content: center;">
                <div class="col-4 col-lg-2 text-center" v-for="time in times" :key="time.id">
                    <ClockElement
                        v-bind:time="time"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ClockElement from './ClockElement.vue'

    export default {
        name: 'begin-time',
        components: {
            ClockElement
        },
        props: {},
        data() {
            return {
                times: [
                    '9-00',
                    '11-00',
                    '13-00',
                    '15-00',
                    '17-00',
                ]
            }
        }
    }
</script>

<style scoped>
    .large-text {
        color: #ed1720;
        font-weight: bold;
        font-size: 24px;
        text-align: center;
    }
</style>
