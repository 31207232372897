<template>
    <div class="price-frame">
        <div class="row m-0">
            <div class="title">Стоимость проживания в сутки за номер (дом):</div>
            <table class="table table-hover border border-secondary">
                <thead>
                <tr class="month">
                    <td></td>
                    <td>май, октябрь</td>
                    <td>июнь</td>
                    <td>июль-август</td>
                    <td>сентябрь</td>
                    <td>ноябрь-апрель</td>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th>Апартаменты с кухней</th>
                    <td>3500</td>
                    <td>4000</td>
                    <td>6000</td>
                    <td>4500</td>
                    <td>2500</td>
                </tr>
                <tr>
                    <th>Апартаменты на втором этаже</th>
                    <td>2500</td>
                    <td>3000</td>
                    <td>5000</td>
                    <td>3500</td>
                    <td>1500</td>
                </tr>
                <tr>
                    <th>Половина виллы</th>
                    <td>6000</td>
                    <td>7000</td>
                    <td>10000</td>
                    <td>8000</td>
                    <td>4000</td>
                </tr>
                <tr>
                    <th>Вся вилла</th>
                    <td>12000</td>
                    <td>14000</td>
                    <td>20000</td>
                    <td>16000</td>
                    <td>8000</td>
                </tr>
                </tbody>
            </table>
            <p>
                * на новогодние и майские праздники цена может увеличиваться
                <br/>
                ** минимум 3 суток, если меньше длительность, цена по договоренности
                <br/>
                *** при длительном бронировании от 12 суток, скидки по договоренносии
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PriceFrame',
        props: {}
    }
</script>

<style scoped>
    .table {
        text-align: center;
    }

    .table thead {
        text-align: center;
        font-family: Pattaya;
        color: #008800;
    }

    .table th {
        text-align: center;
        color: #008800;
        font-size: 0.75em;
    }

    .table td {
        text-align: center;
        font-weight: bold;
        padding: 5px;
        font-size: 0.75em;
    }

    table.table tbody {
        border-top: 0;
    }

    table.table tbody tr td,
    table.table thead tr td {
        border-left: 1px solid;
        border-right: 1px solid;
    }

    .month {
      font-size: 1em;
    }
</style>
