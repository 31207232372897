<template>
    <div class="contact-frame mt-5">
        <div class="row m-0">
            <div class="col-lg-6">
                <div class="title">Наши контакты</div>
                <div class="row mx-1 mt-3 text-center">
                    <div class="contact-row">
                        <font-awesome-icon icon="home" size="lg" class="mx-2"/>
                        Абхазия, г. Гагра, Псахара, ул.Гулия, д. 36
                    </div>
                </div>
                <div class="row mx-1 mt-3 text-center">
                    <div class="contact-row">
                        <font-awesome-icon icon="phone" size="lg" class="mx-2"/>
                        <a :href="'tel:+79288542858'" class="link-danger text-underline-hover">+7 (928) 854-28-58</a>, Михаил (WhatsApp)
                    </div>
                </div>
                <div class="row mx-1 mt-3 text-center">
                    <div class="contact-row">
                        <font-awesome-icon icon="phone" size="lg" class="mx-2"/>
                        <a :href="'tel:+79200116125'" class="link-danger text-underline-hover">+7 (920) 011-61-25</a>, Михаил (Мегафон)
                    </div>
                </div>
                <div class="row mx-1 mt-3 text-center">
                    <div class="contact-row">
                        <font-awesome-icon icon="phone" size="lg" class="mx-2"/>
                        <a :href="'tel:+79409922100'" class="link-danger text-underline-hover">+7 (940) 992-21-00</a>, Вячеслав (Аквафон Абхазия)
                    </div>
                </div>
                <div class="row mx-1 mt-3 text-center">
                    <div class="contact-row">
                        <font-awesome-icon icon="envelope" size="lg" class="mx-2"/>
                        <a :href="'mailto:monte-gagra@mail.ru'" class="link-danger text-underline-hover">monte-gagra@mail.ru</a>
                    </div>

                </div>
                <div class="row mx-4 text-center">
                    <div class="col-lg my-3">
                        <a :href="'#'">
                            <img src="@/assets/images/instagram.png" class="w-100"/>
                        </a>
                    </div>
                    <div class="col-lg my-3">
                        <a :href="'#'">
                            <img src="@/assets/images/booking.png" class="w-100"/>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <yandex-map :coords="coords" :zoom="11" :controls="controls" :scroll-zoom="scrollZoom">
                    <ymap-marker
                            marker-type="placemark"
                            :coords="[43.258243, 40.304960]"
                            :markerId="1"
                            :icon="{color: 'red', glyph: 'home'}"
                    />
                </yandex-map>
            </div>
        </div>
    </div>
</template>

<script>
    import {yandexMap, ymapMarker} from 'vue-yandex-maps'

    export default {
        name: 'ContactFrame',
        components: {yandexMap, ymapMarker},
        props: {},
        data () {
            return {
                coords: [43.258243, 40.304960],
                controls: ['zoomControl'],
                scrollZoom: false
            }
        },
    }
</script>

<style scoped>
    .ymap-body {
        width: 100%;
        height: 100%;
    }

    .ymap-container {
        width: 100%;
        height: 400px;
    }

    .fa-phone path, .fa-envelope path, .fa-home path {
        fill: #ed1720
    }

    .contact-row {
        float: left;
        margin-right: 20px;
        margin-bottom: 15px;
    }

    .text-underline-hover {
        text-decoration: none;
    }

    .text-underline-hover:hover {
        text-decoration: underline;
    }

    a {
        font-weight: bold;
    }
</style>
