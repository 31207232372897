<template>
    <div class="reviews-frame">
        <div class="row m-0 mt-3">
            <div class="title">Отзывы</div>
            <div class="row">
                <div class="col-lg-4 text-center" v-for="review in reviews" :key="review.id">
                    <ReviewElement
                        v-bind:photo="review.photo"
                        v-bind:name="review.name"
                        v-bind:text="review.text"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ReviewElement from './ReviewElement.vue'

    export default {
        name: 'ReviewsFrame',
        components: {
            ReviewElement,
        },
        props: {},
        data() {
            return {
                reviews: [
                    {
                        photo: require('../../assets/images/review/p1.jpg'),
                        name: "Мария Иванова",
                        text: "Отличный отель, хорошие добрые хозяева, чистый номер, бонусом горная речка . Спасибо большое за гостеприимство. Обязательно вернёмся ещё"
                    },
                    {
                        photo: require('../../assets/images/review/p2.jpg'),
                        name: "Владимир Светов",
                        text: "Очень радушный, отзывчивый и прекрасный хозяин. Все понравилось."
                    },
                    {
                        photo: require('../../assets/images/review/p3.jpg'),
                        name: "Светлвна Степанова",
                        text: "Идиллическое место на берегу горной реки, от которой веет прохладой. Понравилось всё: функциональность студии, веранда, отношение."
                    }
                ]
            }
        },
    }
</script>

<style scoped>

</style>
