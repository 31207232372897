<template>
    <div class="review-element">
        <div class="photo mt-3" v-bind:style="{ backgroundImage: 'url(' + photo + ')' }"></div>
        <div class="name">{{name}}</div>
        <div class="text">{{text}}</div>
    </div>
</template>

<script>
    export default {
        name: 'ReviewsFrame',
        props: {
            photo: String,
            name: String,
            text: String,
        },
        data() {
            return {}
        },
    }
</script>

<style scoped>
    .photo {
        display: block;
        width: 150px;
        height: 150px;
        margin: 0 auto;
        border-radius: 75px;
        background-position: center;
        background-size: cover;
    }
    .name {
        color: #ed1720;
        font-weight: bold;
        font-size: 24px;
        text-shadow: 1px 1px 1px rgb(0 0 0);
    }
    .text {
        background-color: #ffffff1a;
    }
</style>
