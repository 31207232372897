<template>
    <div class="footer-frame">
            © Monte-Gagra, {{ new Date().getFullYear() }}.
    </div>
</template>

<script>
    export default {
        name: 'FooterFrame',
        props: {},
    }
</script>

<style scoped>
    .footer-frame {
        height: 75px;
        margin-top: 25px;
        background-color: #00000095;
        color: white;
        text-align: center;
        padding-top: 25px;
    }
</style>
