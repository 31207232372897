<template>
    <div class="price-frame">
        <div class="row m-0">
            <div class="col-lg-6">
                <div class="card">
                    <div class="large">
                        Прокат одного квадроцикла
                    </div>
                    <div class="price">5000 руб.</div>
                    <div class="small">
                        Допускается поездка двух человек на одном квадроцикле за ту же сумму.
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="card col-lg-6">
                    <div class="large">
                        Поездка с инструктором на пассажирском месте
                    </div>
                    <div class="price">3000 руб.</div>
                    <div class="small">
                        Одно место на каждой поездке.
                    </div>
                </div>
            </div>

            <div class="large warning">Бронируйте места заранее, количество квадроциклов ограничено!</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PriceFrame',
        props: {}
    }
</script>

<style scoped>
    .card {
        width: 100%;
    }
    .large {
        font-size: 1.5em;
        font-weight: bold;
        text-align: center;
        color: #008800;
        padding: 15px 0;
    }

    .small {
        font-size: 1em;
        font-weight: bold;
        text-align: center;
        padding: 15px 0;
    }
    .warning {
        color: #ed1720;
        text-shadow: 1px 1px 1px #000000;
    }
    .price {
        text-align: center;
        font-size: 2em;
        font-weight: bold;
        color: #ffffff;
        background-color: #ed1720;
        width: 180px;
        height: 60px;
        border-radius: 60px;
        margin: 0 auto;
        box-shadow: 2px 2px 2px 2px #00000059;
    }
</style>
